import logo from './logo.svg';
import './App.css';
import './components/construccion/construccion.css';
import Construccion from './components/construccion/construccion';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <div className="">
        <div className='construccion'>
          <Construccion/>
        </div>
    </div>
  );
}

export default App;
